import devs from "../src/img/devs.png";
import fund from "../src/img/fund.png";
import stonks from "../src/img/stonks.png";
import trophy from "../src/img/trophy.png";
import profile from "../src/img/profile.png";
import notice from "../src/img/notice.png";
import gamepad from "../src/img/gamepad.png";
import invest from "../src/img/invest.png";
import partners from "../src/img/partners.png";

const cardsData = [
  {
    title: "Developers",
    desc: "Funding platform development and incentivizing new games",
    img: devs,
  },
  {
    title: "Project Initiators and Early Investors",
    desc: "Rewarding initial participants and providing early-stage financial support",
    img: fund,
  },
  {
    title: "Bonuses and Reward Programs",
    desc: "Encouraging player activity and attracting new users",
    img: stonks,
  },
  {
    title: "Reserve Fund",
    desc: "For future project needs, including development and marketing",
    img: trophy,
  },
  {
    title: "Game Developer Rewards",
    desc: "Rewarding for successful user acquisition and retention, and quality projects",
    img: profile,
  },
  {
    title: "Marketing and Partnerships",
    desc: "Attracting partners and conducting marketing activities",
    img: notice,
  },
  {
    title: "Token Distribution Among Current Users",
    desc: "Rewarding activity and achievements",
    img: gamepad,
  },
  {
    title: "Game Accelerator",
    desc: "Investing in promising projects on the platform",
    img: invest,
  },
  {
    title: "Partnership Rewards",
    desc: "Rewarding partners for integrating their solutions and attracting new users.",
    img: partners,
  },
];

export default cardsData;
