import styles from "./GlassButton.module.css";
import arrow from "/Users/xfear/Desktop/arcadia/src/img/arrow.svg";

export default function GlassButton({ children, style }) {
  return (
    <button style={style} className={styles.glassButton}>
      {children} <img src={arrow} alt="" />
    </button>
  );
}
