import styles from "./Header.module.css";
import JoinButton from "../Buttons/JoinButton";
import burger from "/Users/xfear/Desktop/arcadia/src/img/Burger.svg";
import arcadia from "/Users/xfear/Desktop/arcadia/src/img/arcadia.png";
import Menu from "../Menu/Menu";
import { useState } from "react";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const openMenu = () => setIsMenuOpen(true);
  const closeMenu = () => setIsMenuOpen(false);

  return (
    <>
      <div className={styles.navMenuTablet}>
        <img src={arcadia} alt="Arcadia Logo" />
        <button onClick={openMenu}>
          <img src={burger} alt="Menu" />
        </button>
      </div>
      <header className={styles.header}>
        <nav className={styles.navigationMenu}>
          <img src={arcadia} alt="Arcadia Logo" />
          <ul className={styles.links}>
            <li>
              <a href="#Rewards">Rewards</a>
            </li>
            <li>
              <a href="#Tokenomics">Tokenomics</a>
            </li>
            <li>
              <a href="#Loyalty">Loyalty Program</a>
            </li>
            <li>
              <a href="#Footer">Social Networks</a>
            </li>
          </ul>
          <JoinButton />
        </nav>
      </header>
      {isMenuOpen && <Menu onClose={closeMenu} />}
    </>
  );
};

export default Header;
