import Title from "../../Title/Title";
import styles from "./EarnSection.module.css";
import RewardCard from "../../Cards/RewardCard/RewardCard";
import hand from "/Users/xfear/Desktop/arcadia/src/img/hand.png";
import trophy from "/Users/xfear/Desktop/arcadia/src/img/trophy1.png";
export default function EarnSection() {
  return (
    <section id="Rewards">
      <div className={styles.earnWrapper}>
        <div className={styles.earnInfo}>
          <Title>tokenomics</Title>
          <h2>Earn Real Value for Gaming Achievements</h2>
          <p>
            Our token will be used within the platform as a means of exchange
            and value storage. It will be used to pay for access to games,
            purchase in-game items and upgrades, and reward players and
            developers
          </p>
        </div>
        <div className={styles.rewardCards}>
          <RewardCard
            title="Player rewards"
            desc="Players will earn tokens for their  achievements, successes, 
and  participation in contests. This will  incentivize active 
participation and  bring real value to their efforts."
            bg={hand}
          ></RewardCard>
          <RewardCard
            title="Developer rewards"
            desc="Game developers will earn a percentage of the revenue generated by their games. This will encourage the creation 
of high quality and innovative games on our platform."
            bg={trophy}
          ></RewardCard>
        </div>
      </div>
    </section>
  );
}
