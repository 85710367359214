import styles from "./SuccessSection.module.css";

export default function SuccessSection() {
  return (
    <section id="Success">
      <div className={styles.successWrapper}>
        <h2>Our Path to Success</h2>
        <div className={styles.waysListWrapper}>
          <ul className={styles.waysList}>
            <li>
              <h3>01</h3>
              <p>
                We will develop a comprehensive marketing strategy, including
                targeted advertising, influencer collaborations, events and
                contests, and active promotion on social media.
              </p>
            </li>
            <li>
              <h3>02</h3>
              <p>
                Our development plan includes expanding the game library,
                improving the platform and developer tools, and attracting more
                players and developers. We also plan to establish partnerships
                with key gaming companies.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}
