import styles from "./Card.module.css";
import GlassButton from "../Buttons/GlassButton";

export default function Card({
  title,
  desc,
  img,
  alt,
  buttonText,
  buttonStyle,
}) {
  return (
    <div className={styles.card}>
      <h3>{title}</h3>
      <p>{desc}</p>
      <img className={styles.cardImg} src={img} alt={alt} />
      <GlassButton style={buttonStyle}>{buttonText}</GlassButton>
    </div>
  );
}
