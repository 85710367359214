import styles from "./Footer.module.css";
import arcadia from "/Users/xfear/Desktop/arcadia/src/img/arcadia.png";
import tg from "/Users/xfear/Desktop/arcadia/src/img/tg.svg";
import dc from "/Users/xfear/Desktop/arcadia/src/img/dc.svg";
import x from "/Users/xfear/Desktop/arcadia/src/img/x.svg";

export default function Footer() {
  return (
    <footer id="Footer">
      <div className={styles.footerWrapper}>
        <p>Arcadia</p>
        <ul className={styles.footerLinks}>
          <li>
            <a href="#Rewards">Rewards</a>
          </li>
          <li>
            <a href="#Tokenomics">Tokenomics</a>
          </li>
          <li>
            <a href="#Loyalty">Loyalty Program</a>
          </li>
        </ul>
        <ul className={styles.footerSocials}>
          <li>
            <a href="">
              <img src={tg} alt="" />
            </a>
          </li>
          <li>
            <a href="">
              <img src={dc} alt="" />
            </a>
          </li>
          <li>
            <a href="">
              <img src={x} alt="" />
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}
