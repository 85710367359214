import styles from "./AboutSection.module.css";
import star from "/Users/xfear/Desktop/arcadia/src/img/star.svg";
import stats from "/Users/xfear/Desktop/arcadia/src/img/stats.svg";
import singleCoin from "/Users/xfear/Desktop/arcadia/src/img/single-coin.svg";
import Title from "../../Title/Title";

export default function AboutSection() {
  return (
    <section id="About">
      <div className={styles.aboutWrapper}>
        <Title>games and cryptocurency</Title>
        <p>
          Our project is an innovative platform that combines online games and
          cryptocurrency. We offer users unique opportunity to play various
          games and earn tokens with real value. Our mission - is to create a
          new economic model that rewards both players and developers, making
          the gaming process fair and profitable for all participants.
        </p>
        <ul className={styles.aboutList}>
          <li>
            <img src={star} alt="" />
            <p>online games</p>
          </li>
          <li>
            <img src={singleCoin} alt="" />
            <p>cryptocurency</p>
          </li>
          <li>
            <img src={stats} alt="" />
            <p>new ecomomic model</p>
          </li>
        </ul>
      </div>
    </section>
  );
}
