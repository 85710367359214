import styles from "./RewardCard.module.css";

export default function RewardCard({ title, desc, bg }) {
  return (
    <div
      style={{
        background: `url(${bg}) center/contain no-repeat,linear-gradient(180deg, #112c50 0%, #368dff 54.5%, #bcd9fe 100%)`,
      }}
      className={styles.rewardCard}
    >
      <div className={styles.textWrapper}>
        <h3>{title}</h3>
        <p>{desc}</p>
      </div>
    </div>
  );
}
