import styles from "./SliderCard.module.css";

export default function SliderCard({ title, desc, img }) {
  return (
    <div
      className={styles.sliderCard}
      style={{
        backgroundImage: `url(${img}), linear-gradient(180deg, #0976ba 0%, #043654 100%)`,
        backgroundSize: "200px,cover ",
        backgroundPosition: "center top 20px,center",
        backgroundRepeat: "no-repeat, no-repeat",
      }}
    >
      <div className={styles.cardContent}>
        <h3 className={styles.cardTitle}>{title}</h3>
        <p className={styles.cardDescription}>{desc}</p>
      </div>
    </div>
  );
}
