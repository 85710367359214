import group from "/Users/xfear/Desktop/arcadia/src/img/group.png";
import Card from "../Cards/Card";
import styles from "./SolutionSection.module.css";
import phone from "/Users/xfear/Desktop/arcadia/src/img/phone.png";
import adventure from "/Users/xfear/Desktop/arcadia/src/img/adventure.png";

export default function SolutionSection() {
  return (
    <section id="Solution">
      <div className={styles.sectionWrapper}>
        <div className={styles.solutionWrapper}>
          <h2>Why traditional games fall short: the solution is here</h2>
          <p>
            <span style={{ color: "#73777B" }}>
              Modern online games often have unfair monetization models that
              don’t allow players to gain real value from their time and
              efforts. There is also a lack of trust between players and
              developers, and independent developers have limited opportunities
              to realize their ideas.
            </span>
            <br />
            We offer a platform where players can play various games and earn
            tokens with real value. Developers get access to our resources and
            tools to create and publish their games, earning a share of the
            revenue generated by their projects.
          </p>
          <div className={styles.profileGroup}>
            <img src={group} alt="Profile Group" />
          </div>
        </div>
        <div className={styles.cardsWrapper}>
          <Card
            title="flash-style games"
            desc="Our platform will feature various flash-style 
            games integrated with our cryptocurrency.
             Users can earn tokens by playing games and achieving success."
            img={phone}
            alt={"Привет!"}
            buttonText="Claim coins"
          />
          <Card
            title="resources for creating games"
            desc="We will provide developers with access to our 
resources and tools for creating games. Developers can upload their games to our platform, where they will be reviewed for quality and  standards compliance before being published"
            img={adventure}
            buttonText="Start creating"
            buttonStyle={{ bottom: "18.5%" }}
          />
        </div>
      </div>
    </section>
  );
}
