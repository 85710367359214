import JoinButton from "../../Buttons/JoinButton";
import styles from "../PlayAndEarnSection/PlayAndEarnSection.module.css";
import aCoin from "/Users/xfear/Desktop/arcadia/src/img/a-coin.svg";
import emily from "/Users/xfear/Desktop/arcadia/src/img/emily.svg";
import mike from "/Users/xfear/Desktop/arcadia/src/img/mike.svg";
import jane from "/Users/xfear/Desktop/arcadia/src/img/jane.svg";
import coin from "/Users/xfear/Desktop/arcadia/src/img/coin.svg";

export default function MainSection() {
  return (
    <section id="Main">
      <div className={styles.mainSectionWrapper}>
        <h1 className={styles.h1}>Play and Earn</h1>
        <h2 className={styles.h2}>Your victories are worth more</h2>
        <JoinButton></JoinButton>

        <div className={styles.avatars}>
          <img className={styles.aCoin} src={aCoin} alt="" />
          <img className={styles.emily} src={emily} alt="" />
          <img className={styles.mike} src={mike} alt="" />
          <img className={styles.jane} src={jane} alt="" />
          <img className={styles.coin} src={coin} alt="" />
        </div>
      </div>
    </section>
  );
}
