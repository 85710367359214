import styles from "./Menu.module.css";
import JoinButton from "../Buttons/JoinButton";

const Menu = ({ onClose }) => {
  const handleLinkClick = () => {
    onClose(); 
  };

  return (
    <div className={styles.menuOverlay} onClick={onClose}>
      <div className={styles.menuContent} onClick={(e) => e.stopPropagation()}>
        <button className={styles.closeButton} onClick={onClose}>
          <svg
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="30"
              height="5"
              rx="1"
              transform="matrix(0.704444 -0.70976 0.704444 0.70976 0 22)"
              fill="white"
            />
            <rect
              width="30"
              height="5"
              rx="1"
              transform="matrix(0.704444 0.70976 -0.704444 0.70976 4 1)"
              fill="white"
            />
          </svg>
        </button>
        <p className={styles.arcadiaLogo}>Arcadia</p>
        <ul className={styles.menuLinks}>
          <li>
            <a href="#Rewards" onClick={handleLinkClick}>
              Rewards
            </a>
          </li>
          <li>
            <a href="#Tokenomics" onClick={handleLinkClick}>
              Tokenomics
            </a>
          </li>
          <li>
            <a href="#Loyalty" onClick={handleLinkClick}>
              Loyalty Program
            </a>
          </li>
          <li>
            <a href="#Footer" onClick={handleLinkClick}>
              Social Networks
            </a>
          </li>
        </ul>
        <JoinButton />
      </div>
    </div>
  );
};

export default Menu;
