import JoinButton from "../../Buttons/JoinButton";
import styles from "./JoinSection.module.css";
import Title from "../../Title/Title";
import girl from "/Users/xfear/Desktop/arcadia/src/img/girl.png";

export default function JoinSection() {
  return (
    <section id="Join">
      <div className={styles.joinWrapper}>
        <div className={styles.joinInfo}>
          <Title>together towards success</Title>
          <h2>Join the New Gaming Revolution</h2>
          <p>
            Our project is an innovative platform that combines games and
            cryptocurrency, offering users the opportunity to earn real value by
            playing games. We believe our platform will become a significant
            player in the online gaming industry, bringing satisfaction to both
            users and developers.
          </p>
          <JoinButton />
        </div>
        <img src={girl} alt="JoinGirl" className={styles.joinImg} />
      </div>
    </section>
  );
}
