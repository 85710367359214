import styles from "./RiskSection.module.css";
import checkbox from "/Users/xfear/Desktop/arcadia/src/img/checkbox.png";

export default function RisksSection() {
  return (
    <section id="Risks">
      <div className={styles.risksWrapper}>
        <h2>Risks and Limitations: How We Overcome Them</h2>
        <div className={styles.waysWrapper}>
          <ul className={styles.waysOfOvercoming}>
            <li>
              <img className={styles.checkbox1} src={checkbox} alt="" />
              <p>
                We identify potential risks such as data security,
                cryptocurrency regulation changes, and competition. We will
                develop appropriate strategies and measures to manage these
                risks.
              </p>
            </li>
            <li>
              <img className={styles.checkbox2} src={checkbox} alt="" />
              <p>
                {" "}
                We recognize that we may face limitations such as limited
                cryptocurrency adoption and regulatory barriers. We will work
                within these constraints and strive to overcome them.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}
