import Header from "./Components/Header/Header";
import "./App.css";
import PlayAndEarnSection from "./Components/Sections/PlayAndEarnSection/PlayAndEarnSection";
import AboutSection from "./Components/Sections/About/AboutSection";
import SolutionSection from "./Components/SolutionSection/SolutionSection";
import EarnSection from "./Components/Sections/EarnSection/EarnSection";
import SuccessSection from "./Components/Sections/SuccessSection/SuccessSection";
import RisksSection from "./Components/Sections/RisksSection/RiskSection";
import JoinSection from "./Components/Sections/JoinSection/JoinSection";
import TokenomicsSection from "./Components/Sections/TokenomicsSection/TokenomicsSection";
import LoyaltySection from "./Components/Sections/LoyaltySection/LoyaltySection";
import Footer from "./Components/Footer/Footer";

function App() {
  return (
    <>
      <Header />
      <main>
        <PlayAndEarnSection />
        <AboutSection />
        <SolutionSection />
        <EarnSection />
        <SuccessSection />
        <RisksSection />
        <JoinSection />
        <TokenomicsSection />
        <LoyaltySection />
        <Footer />
      </main>
    </>
  );
}

export default App;
